exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-side-cover-tsx": () => import("./../../../src/pages/about-side-cover.tsx" /* webpackChunkName: "component---src-pages-about-side-cover-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-billing-tsx": () => import("./../../../src/pages/account-billing.tsx" /* webpackChunkName: "component---src-pages-account-billing-tsx" */),
  "component---src-pages-account-general-tsx": () => import("./../../../src/pages/account-general.tsx" /* webpackChunkName: "component---src-pages-account-general-tsx" */),
  "component---src-pages-account-notifications-tsx": () => import("./../../../src/pages/account-notifications.tsx" /* webpackChunkName: "component---src-pages-account-notifications-tsx" */),
  "component---src-pages-account-security-tsx": () => import("./../../../src/pages/account-security.tsx" /* webpackChunkName: "component---src-pages-account-security-tsx" */),
  "component---src-pages-agency-tsx": () => import("./../../../src/pages/agency.tsx" /* webpackChunkName: "component---src-pages-agency-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../../../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-blocks-app-stats-index-tsx": () => import("./../../../src/pages/blocks/app-stats/index.tsx" /* webpackChunkName: "component---src-pages-blocks-app-stats-index-tsx" */),
  "component---src-pages-blocks-app-stats-simple-tsx": () => import("./../../../src/pages/blocks/app-stats/simple.tsx" /* webpackChunkName: "component---src-pages-blocks-app-stats-simple-tsx" */),
  "component---src-pages-blocks-app-stats-with-brand-icon-tsx": () => import("./../../../src/pages/blocks/app-stats/with-brand-icon.tsx" /* webpackChunkName: "component---src-pages-blocks-app-stats-with-brand-icon-tsx" */),
  "component---src-pages-blocks-app-stats-with-shared-borders-tsx": () => import("./../../../src/pages/blocks/app-stats/with-shared-borders.tsx" /* webpackChunkName: "component---src-pages-blocks-app-stats-with-shared-borders-tsx" */),
  "component---src-pages-blocks-authentication-auth-form-with-dark-bg-tsx": () => import("./../../../src/pages/blocks/authentication/auth-form-with-dark-bg.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-auth-form-with-dark-bg-tsx" */),
  "component---src-pages-blocks-authentication-index-tsx": () => import("./../../../src/pages/blocks/authentication/index.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-index-tsx" */),
  "component---src-pages-blocks-authentication-reset-password-simple-form-tsx": () => import("./../../../src/pages/blocks/authentication/reset-password-simple-form.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-reset-password-simple-form-tsx" */),
  "component---src-pages-blocks-authentication-sign-in-form-with-cover-image-tsx": () => import("./../../../src/pages/blocks/authentication/sign-in-form-with-cover-image.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-sign-in-form-with-cover-image-tsx" */),
  "component---src-pages-blocks-authentication-sign-up-form-with-cover-image-tsx": () => import("./../../../src/pages/blocks/authentication/sign-up-form-with-cover-image.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-sign-up-form-with-cover-image-tsx" */),
  "component---src-pages-blocks-authentication-simple-sign-in-form-tsx": () => import("./../../../src/pages/blocks/authentication/simple-sign-in-form.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-simple-sign-in-form-tsx" */),
  "component---src-pages-blocks-authentication-simple-sign-up-form-tsx": () => import("./../../../src/pages/blocks/authentication/simple-sign-up-form.tsx" /* webpackChunkName: "component---src-pages-blocks-authentication-simple-sign-up-form-tsx" */),
  "component---src-pages-blocks-banners-index-tsx": () => import("./../../../src/pages/blocks/banners/index.tsx" /* webpackChunkName: "component---src-pages-blocks-banners-index-tsx" */),
  "component---src-pages-blocks-banners-mui-standard-snack-bars-tsx": () => import("./../../../src/pages/blocks/banners/mui-standard-snack-bars.tsx" /* webpackChunkName: "component---src-pages-blocks-banners-mui-standard-snack-bars-tsx" */),
  "component---src-pages-blocks-banners-simple-snack-bar-tsx": () => import("./../../../src/pages/blocks/banners/simple-snack-bar.tsx" /* webpackChunkName: "component---src-pages-blocks-banners-simple-snack-bar-tsx" */),
  "component---src-pages-blocks-blog-blog-cards-with-full-background-image-tsx": () => import("./../../../src/pages/blocks/blog/blog-cards-with-full-background-image.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-blog-cards-with-full-background-image-tsx" */),
  "component---src-pages-blocks-blog-blog-with-large-image-tsx": () => import("./../../../src/pages/blocks/blog/blog-with-large-image.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-blog-with-large-image-tsx" */),
  "component---src-pages-blocks-blog-horizontally-aligned-blog-card-with-shaped-image-tsx": () => import("./../../../src/pages/blocks/blog/horizontally-aligned-blog-card-with-shaped-image.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-horizontally-aligned-blog-card-with-shaped-image-tsx" */),
  "component---src-pages-blocks-blog-index-tsx": () => import("./../../../src/pages/blocks/blog/index.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-index-tsx" */),
  "component---src-pages-blocks-blog-simple-vertical-blog-cards-tsx": () => import("./../../../src/pages/blocks/blog/simple-vertical-blog-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-simple-vertical-blog-cards-tsx" */),
  "component---src-pages-blocks-blog-vertical-minimal-designed-blog-cards-tsx": () => import("./../../../src/pages/blocks/blog/vertical-minimal-designed-blog-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-vertical-minimal-designed-blog-cards-tsx" */),
  "component---src-pages-blocks-blog-vertically-aligned-blog-card-overlapped-with-description-box-tsx": () => import("./../../../src/pages/blocks/blog/vertically-aligned-blog-card-overlapped-with-description-box.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-vertically-aligned-blog-card-overlapped-with-description-box-tsx" */),
  "component---src-pages-blocks-blog-vertically-aligned-blog-cards-with-shaped-image-tsx": () => import("./../../../src/pages/blocks/blog/vertically-aligned-blog-cards-with-shaped-image.tsx" /* webpackChunkName: "component---src-pages-blocks-blog-vertically-aligned-blog-cards-with-shaped-image-tsx" */),
  "component---src-pages-blocks-cards-card-with-add-button-tsx": () => import("./../../../src/pages/blocks/cards/card-with-add-button.tsx" /* webpackChunkName: "component---src-pages-blocks-cards-card-with-add-button-tsx" */),
  "component---src-pages-blocks-cards-card-with-checkboxes-tsx": () => import("./../../../src/pages/blocks/cards/card-with-checkboxes.tsx" /* webpackChunkName: "component---src-pages-blocks-cards-card-with-checkboxes-tsx" */),
  "component---src-pages-blocks-cards-card-with-color-accent-tsx": () => import("./../../../src/pages/blocks/cards/card-with-color-accent.tsx" /* webpackChunkName: "component---src-pages-blocks-cards-card-with-color-accent-tsx" */),
  "component---src-pages-blocks-cards-index-tsx": () => import("./../../../src/pages/blocks/cards/index.tsx" /* webpackChunkName: "component---src-pages-blocks-cards-index-tsx" */),
  "component---src-pages-blocks-category-showcases-index-tsx": () => import("./../../../src/pages/blocks/category-showcases/index.tsx" /* webpackChunkName: "component---src-pages-blocks-category-showcases-index-tsx" */),
  "component---src-pages-blocks-category-showcases-on-spanning-columns-tsx": () => import("./../../../src/pages/blocks/category-showcases/on-spanning-columns.tsx" /* webpackChunkName: "component---src-pages-blocks-category-showcases-on-spanning-columns-tsx" */),
  "component---src-pages-blocks-category-showcases-showcase-bg-image-tsx": () => import("./../../../src/pages/blocks/category-showcases/showcase-bg-image.tsx" /* webpackChunkName: "component---src-pages-blocks-category-showcases-showcase-bg-image-tsx" */),
  "component---src-pages-blocks-category-showcases-showcase-grid-tsx": () => import("./../../../src/pages/blocks/category-showcases/showcase-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-category-showcases-showcase-grid-tsx" */),
  "component---src-pages-blocks-category-showcases-with-image-grid-tsx": () => import("./../../../src/pages/blocks/category-showcases/with-image-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-category-showcases-with-image-grid-tsx" */),
  "component---src-pages-blocks-checkout-pages-index-tsx": () => import("./../../../src/pages/blocks/checkout-pages/index.tsx" /* webpackChunkName: "component---src-pages-blocks-checkout-pages-index-tsx" */),
  "component---src-pages-blocks-checkout-pages-with-two-columns-tsx": () => import("./../../../src/pages/blocks/checkout-pages/with-two-columns.tsx" /* webpackChunkName: "component---src-pages-blocks-checkout-pages-with-two-columns-tsx" */),
  "component---src-pages-blocks-cta-cta-aligned-left-with-typed-text-tsx": () => import("./../../../src/pages/blocks/cta/cta-aligned-left-with-typed-text.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-aligned-left-with-typed-text-tsx" */),
  "component---src-pages-blocks-cta-cta-simple-centered-tsx": () => import("./../../../src/pages/blocks/cta/cta-simple-centered.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-simple-centered-tsx" */),
  "component---src-pages-blocks-cta-cta-with-app-store-buttons-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-app-store-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-app-store-buttons-tsx" */),
  "component---src-pages-blocks-cta-cta-with-cover-image-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-cover-image.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-cover-image-tsx" */),
  "component---src-pages-blocks-cta-cta-with-illustration-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-illustration.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-illustration-tsx" */),
  "component---src-pages-blocks-cta-cta-with-input-field-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-input-field.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-input-field-tsx" */),
  "component---src-pages-blocks-cta-cta-with-right-app-store-buttons-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-right-app-store-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-right-app-store-buttons-tsx" */),
  "component---src-pages-blocks-cta-cta-with-right-buttons-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-right-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-right-buttons-tsx" */),
  "component---src-pages-blocks-cta-cta-with-right-download-button-tsx": () => import("./../../../src/pages/blocks/cta/cta-with-right-download-button.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-cta-with-right-download-button-tsx" */),
  "component---src-pages-blocks-cta-index-tsx": () => import("./../../../src/pages/blocks/cta/index.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-index-tsx" */),
  "component---src-pages-blocks-cta-support-center-cta-tsx": () => import("./../../../src/pages/blocks/cta/support-center-cta.tsx" /* webpackChunkName: "component---src-pages-blocks-cta-support-center-cta-tsx" */),
  "component---src-pages-blocks-features-feature-card-with-cta-button-tsx": () => import("./../../../src/pages/blocks/features/feature-card-with-cta-button.tsx" /* webpackChunkName: "component---src-pages-blocks-features-feature-card-with-cta-button-tsx" */),
  "component---src-pages-blocks-features-feature-cards-with-colorfull-icons-and-learn-more-link-tsx": () => import("./../../../src/pages/blocks/features/feature-cards-with-colorfull-icons-and-learn-more-link.tsx" /* webpackChunkName: "component---src-pages-blocks-features-feature-cards-with-colorfull-icons-and-learn-more-link-tsx" */),
  "component---src-pages-blocks-features-feature-grid-with-backgrounds-tsx": () => import("./../../../src/pages/blocks/features/feature-grid-with-backgrounds.tsx" /* webpackChunkName: "component---src-pages-blocks-features-feature-grid-with-backgrounds-tsx" */),
  "component---src-pages-blocks-features-feature-list-with-desktop-app-screenshot-tsx": () => import("./../../../src/pages/blocks/features/feature-list-with-desktop-app-screenshot.tsx" /* webpackChunkName: "component---src-pages-blocks-features-feature-list-with-desktop-app-screenshot-tsx" */),
  "component---src-pages-blocks-features-feature-list-with-form-tsx": () => import("./../../../src/pages/blocks/features/feature-list-with-form.tsx" /* webpackChunkName: "component---src-pages-blocks-features-feature-list-with-form-tsx" */),
  "component---src-pages-blocks-features-features-with-card-representation-tsx": () => import("./../../../src/pages/blocks/features/features-with-card-representation.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-card-representation-tsx" */),
  "component---src-pages-blocks-features-features-with-check-marks-and-abstract-images-tsx": () => import("./../../../src/pages/blocks/features/features-with-check-marks-and-abstract-images.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-check-marks-and-abstract-images-tsx" */),
  "component---src-pages-blocks-features-features-with-illustration-tsx": () => import("./../../../src/pages/blocks/features/features-with-illustration.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-illustration-tsx" */),
  "component---src-pages-blocks-features-features-with-learn-more-link-tsx": () => import("./../../../src/pages/blocks/features/features-with-learn-more-link.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-learn-more-link-tsx" */),
  "component---src-pages-blocks-features-features-with-masonry-cards-and-check-icons-tsx": () => import("./../../../src/pages/blocks/features/features-with-masonry-cards-and-check-icons.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-masonry-cards-and-check-icons-tsx" */),
  "component---src-pages-blocks-features-features-with-minimal-design-tsx": () => import("./../../../src/pages/blocks/features/features-with-minimal-design.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-minimal-design-tsx" */),
  "component---src-pages-blocks-features-features-with-mobile-screenshot-tsx": () => import("./../../../src/pages/blocks/features/features-with-mobile-screenshot.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-mobile-screenshot-tsx" */),
  "component---src-pages-blocks-features-features-with-simple-icons-tsx": () => import("./../../../src/pages/blocks/features/features-with-simple-icons.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-simple-icons-tsx" */),
  "component---src-pages-blocks-features-features-with-simple-left-aligned-icons-tsx": () => import("./../../../src/pages/blocks/features/features-with-simple-left-aligned-icons.tsx" /* webpackChunkName: "component---src-pages-blocks-features-features-with-simple-left-aligned-icons-tsx" */),
  "component---src-pages-blocks-features-index-tsx": () => import("./../../../src/pages/blocks/features/index.tsx" /* webpackChunkName: "component---src-pages-blocks-features-index-tsx" */),
  "component---src-pages-blocks-features-one-line-feature-list-with-check-marks-tsx": () => import("./../../../src/pages/blocks/features/one-line-feature-list-with-check-marks.tsx" /* webpackChunkName: "component---src-pages-blocks-features-one-line-feature-list-with-check-marks-tsx" */),
  "component---src-pages-blocks-features-simple-centered-tsx": () => import("./../../../src/pages/blocks/features/simple-centered.tsx" /* webpackChunkName: "component---src-pages-blocks-features-simple-centered-tsx" */),
  "component---src-pages-blocks-features-simple-features-with-alternate-cards-tsx": () => import("./../../../src/pages/blocks/features/simple-features-with-alternate-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-features-simple-features-with-alternate-cards-tsx" */),
  "component---src-pages-blocks-features-simple-left-aligned-tsx": () => import("./../../../src/pages/blocks/features/simple-left-aligned.tsx" /* webpackChunkName: "component---src-pages-blocks-features-simple-left-aligned-tsx" */),
  "component---src-pages-blocks-form-controls-custom-radio-group-tsx": () => import("./../../../src/pages/blocks/form-controls/custom-radio-group.tsx" /* webpackChunkName: "component---src-pages-blocks-form-controls-custom-radio-group-tsx" */),
  "component---src-pages-blocks-form-controls-custom-select-tsx": () => import("./../../../src/pages/blocks/form-controls/custom-select.tsx" /* webpackChunkName: "component---src-pages-blocks-form-controls-custom-select-tsx" */),
  "component---src-pages-blocks-form-controls-index-tsx": () => import("./../../../src/pages/blocks/form-controls/index.tsx" /* webpackChunkName: "component---src-pages-blocks-form-controls-index-tsx" */),
  "component---src-pages-blocks-form-controls-stacked-custom-radio-group-tsx": () => import("./../../../src/pages/blocks/form-controls/stacked-custom-radio-group.tsx" /* webpackChunkName: "component---src-pages-blocks-form-controls-stacked-custom-radio-group-tsx" */),
  "component---src-pages-blocks-form-controls-toggle-button-tsx": () => import("./../../../src/pages/blocks/form-controls/toggle-button.tsx" /* webpackChunkName: "component---src-pages-blocks-form-controls-toggle-button-tsx" */),
  "component---src-pages-blocks-form-layouts-account-tsx": () => import("./../../../src/pages/blocks/form-layouts/account.tsx" /* webpackChunkName: "component---src-pages-blocks-form-layouts-account-tsx" */),
  "component---src-pages-blocks-form-layouts-contact-tsx": () => import("./../../../src/pages/blocks/form-layouts/contact.tsx" /* webpackChunkName: "component---src-pages-blocks-form-layouts-contact-tsx" */),
  "component---src-pages-blocks-form-layouts-index-tsx": () => import("./../../../src/pages/blocks/form-layouts/index.tsx" /* webpackChunkName: "component---src-pages-blocks-form-layouts-index-tsx" */),
  "component---src-pages-blocks-heroes-full-screen-hero-with-image-slider-tsx": () => import("./../../../src/pages/blocks/heroes/full-screen-hero-with-image-slider.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-full-screen-hero-with-image-slider-tsx" */),
  "component---src-pages-blocks-heroes-full-screen-hero-with-logo-grid-tsx": () => import("./../../../src/pages/blocks/heroes/full-screen-hero-with-logo-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-full-screen-hero-with-logo-grid-tsx" */),
  "component---src-pages-blocks-heroes-full-screen-hero-with-promo-images-and-typed-text-tsx": () => import("./../../../src/pages/blocks/heroes/full-screen-hero-with-promo-images-and-typed-text.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-full-screen-hero-with-promo-images-and-typed-text-tsx" */),
  "component---src-pages-blocks-heroes-full-screen-hero-with-subscription-form-tsx": () => import("./../../../src/pages/blocks/heroes/full-screen-hero-with-subscription-form.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-full-screen-hero-with-subscription-form-tsx" */),
  "component---src-pages-blocks-heroes-hero-for-ecommerce-app-tsx": () => import("./../../../src/pages/blocks/heroes/hero-for-ecommerce-app.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-for-ecommerce-app-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-background-video-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-background-video.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-background-video-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-dashboard-screenshot-and-cta-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-dashboard-screenshot-and-cta.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-dashboard-screenshot-and-cta-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-form-and-background-gradient-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-form-and-background-gradient.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-form-and-background-gradient-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-illustration-and-cta-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-illustration-and-cta.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-illustration-and-cta-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-illustration-and-search-bar-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-illustration-and-search-bar.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-illustration-and-search-bar-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-logo-grid-and-desktop-screenshot-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-logo-grid-and-desktop-screenshot.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-logo-grid-and-desktop-screenshot-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-mobile-app-screenshot-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-mobile-app-screenshot.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-mobile-app-screenshot-tsx" */),
  "component---src-pages-blocks-heroes-hero-with-primary-background-and-desktop-screenshot-tsx": () => import("./../../../src/pages/blocks/heroes/hero-with-primary-background-and-desktop-screenshot.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-hero-with-primary-background-and-desktop-screenshot-tsx" */),
  "component---src-pages-blocks-heroes-index-tsx": () => import("./../../../src/pages/blocks/heroes/index.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-index-tsx" */),
  "component---src-pages-blocks-heroes-simple-hero-with-bottom-video-tsx": () => import("./../../../src/pages/blocks/heroes/simple-hero-with-bottom-video.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-simple-hero-with-bottom-video-tsx" */),
  "component---src-pages-blocks-heroes-simple-hero-with-cta-tsx": () => import("./../../../src/pages/blocks/heroes/simple-hero-with-cta.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-simple-hero-with-cta-tsx" */),
  "component---src-pages-blocks-heroes-simple-hero-with-image-and-cta-buttons-tsx": () => import("./../../../src/pages/blocks/heroes/simple-hero-with-image-and-cta-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-simple-hero-with-image-and-cta-buttons-tsx" */),
  "component---src-pages-blocks-heroes-simple-hero-with-search-box-tsx": () => import("./../../../src/pages/blocks/heroes/simple-hero-with-search-box.tsx" /* webpackChunkName: "component---src-pages-blocks-heroes-simple-hero-with-search-box-tsx" */),
  "component---src-pages-blocks-index-tsx": () => import("./../../../src/pages/blocks/index.tsx" /* webpackChunkName: "component---src-pages-blocks-index-tsx" */),
  "component---src-pages-blocks-lists-index-tsx": () => import("./../../../src/pages/blocks/lists/index.tsx" /* webpackChunkName: "component---src-pages-blocks-lists-index-tsx" */),
  "component---src-pages-blocks-lists-list-with-nested-item-tsx": () => import("./../../../src/pages/blocks/lists/list-with-nested-item.tsx" /* webpackChunkName: "component---src-pages-blocks-lists-list-with-nested-item-tsx" */),
  "component---src-pages-blocks-lists-list-with-vertical-line-tsx": () => import("./../../../src/pages/blocks/lists/list-with-vertical-line.tsx" /* webpackChunkName: "component---src-pages-blocks-lists-list-with-vertical-line-tsx" */),
  "component---src-pages-blocks-lists-with-avatars-tsx": () => import("./../../../src/pages/blocks/lists/with-avatars.tsx" /* webpackChunkName: "component---src-pages-blocks-lists-with-avatars-tsx" */),
  "component---src-pages-blocks-logo-grid-index-tsx": () => import("./../../../src/pages/blocks/logo-grid/index.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-index-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-simple-centered-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-simple-centered.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-simple-centered-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-with-boxed-logos-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-with-boxed-logos.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-with-boxed-logos-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-with-dark-background-and-simple-description-box-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-with-dark-background-and-simple-description-box.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-with-dark-background-and-simple-description-box-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-with-left-aligned-description-box-and-boxed-logos-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-with-left-aligned-description-box-and-boxed-logos.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-with-left-aligned-description-box-and-boxed-logos-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-with-left-aligned-description-box-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-with-left-aligned-description-box.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-with-left-aligned-description-box-tsx" */),
  "component---src-pages-blocks-logo-grid-logo-grid-with-swiper-and-brand-background-color-tsx": () => import("./../../../src/pages/blocks/logo-grid/logo-grid-with-swiper-and-brand-background-color.tsx" /* webpackChunkName: "component---src-pages-blocks-logo-grid-logo-grid-with-swiper-and-brand-background-color-tsx" */),
  "component---src-pages-blocks-newsletters-index-tsx": () => import("./../../../src/pages/blocks/newsletters/index.tsx" /* webpackChunkName: "component---src-pages-blocks-newsletters-index-tsx" */),
  "component---src-pages-blocks-newsletters-newsletter-with-card-tsx": () => import("./../../../src/pages/blocks/newsletters/newsletter-with-card.tsx" /* webpackChunkName: "component---src-pages-blocks-newsletters-newsletter-with-card-tsx" */),
  "component---src-pages-blocks-newsletters-newsletter-with-image-tsx": () => import("./../../../src/pages/blocks/newsletters/newsletter-with-image.tsx" /* webpackChunkName: "component---src-pages-blocks-newsletters-newsletter-with-image-tsx" */),
  "component---src-pages-blocks-newsletters-with-dark-background-tsx": () => import("./../../../src/pages/blocks/newsletters/with-dark-background.tsx" /* webpackChunkName: "component---src-pages-blocks-newsletters-with-dark-background-tsx" */),
  "component---src-pages-blocks-notifications-index-tsx": () => import("./../../../src/pages/blocks/notifications/index.tsx" /* webpackChunkName: "component---src-pages-blocks-notifications-index-tsx" */),
  "component---src-pages-blocks-notifications-simple-tsx": () => import("./../../../src/pages/blocks/notifications/simple.tsx" /* webpackChunkName: "component---src-pages-blocks-notifications-simple-tsx" */),
  "component---src-pages-blocks-notifications-with-action-buttons-tsx": () => import("./../../../src/pages/blocks/notifications/with-action-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-notifications-with-action-buttons-tsx" */),
  "component---src-pages-blocks-notifications-with-avatar-and-buttons-below-tsx": () => import("./../../../src/pages/blocks/notifications/with-avatar-and-buttons-below.tsx" /* webpackChunkName: "component---src-pages-blocks-notifications-with-avatar-and-buttons-below-tsx" */),
  "component---src-pages-blocks-notifications-with-split-buttons-tsx": () => import("./../../../src/pages/blocks/notifications/with-split-buttons.tsx" /* webpackChunkName: "component---src-pages-blocks-notifications-with-split-buttons-tsx" */),
  "component---src-pages-blocks-page-layouts-index-tsx": () => import("./../../../src/pages/blocks/page-layouts/index.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-index-tsx" */),
  "component---src-pages-blocks-page-layouts-with-fixed-sidebar-tsx": () => import("./../../../src/pages/blocks/page-layouts/with-fixed-sidebar.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-with-fixed-sidebar-tsx" */),
  "component---src-pages-blocks-page-layouts-with-fluid-layout-and-no-sidebar-tsx": () => import("./../../../src/pages/blocks/page-layouts/with-fluid-layout-and-no-sidebar.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-with-fluid-layout-and-no-sidebar-tsx" */),
  "component---src-pages-blocks-page-layouts-with-narrow-layout-and-no-sidebar-tsx": () => import("./../../../src/pages/blocks/page-layouts/with-narrow-layout-and-no-sidebar.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-with-narrow-layout-and-no-sidebar-tsx" */),
  "component---src-pages-blocks-page-layouts-with-three-columns-tsx": () => import("./../../../src/pages/blocks/page-layouts/with-three-columns.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-with-three-columns-tsx" */),
  "component---src-pages-blocks-page-layouts-with-toggled-sidebar-tsx": () => import("./../../../src/pages/blocks/page-layouts/with-toggled-sidebar.tsx" /* webpackChunkName: "component---src-pages-blocks-page-layouts-with-toggled-sidebar-tsx" */),
  "component---src-pages-blocks-popovers-index-tsx": () => import("./../../../src/pages/blocks/popovers/index.tsx" /* webpackChunkName: "component---src-pages-blocks-popovers-index-tsx" */),
  "component---src-pages-blocks-popovers-simple-tsx": () => import("./../../../src/pages/blocks/popovers/simple.tsx" /* webpackChunkName: "component---src-pages-blocks-popovers-simple-tsx" */),
  "component---src-pages-blocks-popovers-stacked-with-footer-actions-tsx": () => import("./../../../src/pages/blocks/popovers/stacked-with-footer-actions.tsx" /* webpackChunkName: "component---src-pages-blocks-popovers-stacked-with-footer-actions-tsx" */),
  "component---src-pages-blocks-popovers-with-recent-posts-tsx": () => import("./../../../src/pages/blocks/popovers/with-recent-posts.tsx" /* webpackChunkName: "component---src-pages-blocks-popovers-with-recent-posts-tsx" */),
  "component---src-pages-blocks-popovers-with-two-column-grid-tsx": () => import("./../../../src/pages/blocks/popovers/with-two-column-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-popovers-with-two-column-grid-tsx" */),
  "component---src-pages-blocks-pricing-compare-table-tsx": () => import("./../../../src/pages/blocks/pricing/compare-table.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-compare-table-tsx" */),
  "component---src-pages-blocks-pricing-index-tsx": () => import("./../../../src/pages/blocks/pricing/index.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-index-tsx" */),
  "component---src-pages-blocks-pricing-single-choice-option-tsx": () => import("./../../../src/pages/blocks/pricing/single-choice-option.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-single-choice-option-tsx" */),
  "component---src-pages-blocks-pricing-with-highlighting-and-primary-color-tsx": () => import("./../../../src/pages/blocks/pricing/with-highlighting-and-primary-color.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-with-highlighting-and-primary-color-tsx" */),
  "component---src-pages-blocks-pricing-with-highlighting-and-secondary-color-tsx": () => import("./../../../src/pages/blocks/pricing/with-highlighting-and-secondary-color.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-with-highlighting-and-secondary-color-tsx" */),
  "component---src-pages-blocks-pricing-with-option-toggler-button-tsx": () => import("./../../../src/pages/blocks/pricing/with-option-toggler-button.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-with-option-toggler-button-tsx" */),
  "component---src-pages-blocks-pricing-with-simple-bordered-cards-tsx": () => import("./../../../src/pages/blocks/pricing/with-simple-bordered-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-with-simple-bordered-cards-tsx" */),
  "component---src-pages-blocks-pricing-with-two-column-and-mixed-height-tsx": () => import("./../../../src/pages/blocks/pricing/with-two-column-and-mixed-height.tsx" /* webpackChunkName: "component---src-pages-blocks-pricing-with-two-column-and-mixed-height-tsx" */),
  "component---src-pages-blocks-product-details-index-tsx": () => import("./../../../src/pages/blocks/product-details/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-details-index-tsx" */),
  "component---src-pages-blocks-product-details-with-image-grid-tsx": () => import("./../../../src/pages/blocks/product-details/with-image-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-product-details-with-image-grid-tsx" */),
  "component---src-pages-blocks-product-details-with-large-image-tsx": () => import("./../../../src/pages/blocks/product-details/with-large-image.tsx" /* webpackChunkName: "component---src-pages-blocks-product-details-with-large-image-tsx" */),
  "component---src-pages-blocks-product-filters-filters-with-dropdown-tsx": () => import("./../../../src/pages/blocks/product-filters/filters-with-dropdown.tsx" /* webpackChunkName: "component---src-pages-blocks-product-filters-filters-with-dropdown-tsx" */),
  "component---src-pages-blocks-product-filters-filters-with-sidebar-tsx": () => import("./../../../src/pages/blocks/product-filters/filters-with-sidebar.tsx" /* webpackChunkName: "component---src-pages-blocks-product-filters-filters-with-sidebar-tsx" */),
  "component---src-pages-blocks-product-filters-index-tsx": () => import("./../../../src/pages/blocks/product-filters/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-filters-index-tsx" */),
  "component---src-pages-blocks-product-grids-index-tsx": () => import("./../../../src/pages/blocks/product-grids/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-grids-index-tsx" */),
  "component---src-pages-blocks-product-grids-minimally-designed-tsx": () => import("./../../../src/pages/blocks/product-grids/minimally-designed.tsx" /* webpackChunkName: "component---src-pages-blocks-product-grids-minimally-designed-tsx" */),
  "component---src-pages-blocks-product-grids-with-cta-button-tsx": () => import("./../../../src/pages/blocks/product-grids/with-cta-button.tsx" /* webpackChunkName: "component---src-pages-blocks-product-grids-with-cta-button-tsx" */),
  "component---src-pages-blocks-product-grids-with-promo-badge-tsx": () => import("./../../../src/pages/blocks/product-grids/with-promo-badge.tsx" /* webpackChunkName: "component---src-pages-blocks-product-grids-with-promo-badge-tsx" */),
  "component---src-pages-blocks-product-pickers-color-picker-tsx": () => import("./../../../src/pages/blocks/product-pickers/color-picker.tsx" /* webpackChunkName: "component---src-pages-blocks-product-pickers-color-picker-tsx" */),
  "component---src-pages-blocks-product-pickers-index-tsx": () => import("./../../../src/pages/blocks/product-pickers/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-pickers-index-tsx" */),
  "component---src-pages-blocks-product-pickers-quantity-picker-tsx": () => import("./../../../src/pages/blocks/product-pickers/quantity-picker.tsx" /* webpackChunkName: "component---src-pages-blocks-product-pickers-quantity-picker-tsx" */),
  "component---src-pages-blocks-product-pickers-size-picker-tsx": () => import("./../../../src/pages/blocks/product-pickers/size-picker.tsx" /* webpackChunkName: "component---src-pages-blocks-product-pickers-size-picker-tsx" */),
  "component---src-pages-blocks-product-quick-views-index-tsx": () => import("./../../../src/pages/blocks/product-quick-views/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-quick-views-index-tsx" */),
  "component---src-pages-blocks-product-quick-views-popup-box-with-product-details-tsx": () => import("./../../../src/pages/blocks/product-quick-views/popup-box-with-product-details.tsx" /* webpackChunkName: "component---src-pages-blocks-product-quick-views-popup-box-with-product-details-tsx" */),
  "component---src-pages-blocks-product-reviews-index-tsx": () => import("./../../../src/pages/blocks/product-reviews/index.tsx" /* webpackChunkName: "component---src-pages-blocks-product-reviews-index-tsx" */),
  "component---src-pages-blocks-product-reviews-review-dialog-tsx": () => import("./../../../src/pages/blocks/product-reviews/review-dialog.tsx" /* webpackChunkName: "component---src-pages-blocks-product-reviews-review-dialog-tsx" */),
  "component---src-pages-blocks-product-reviews-review-overview-tsx": () => import("./../../../src/pages/blocks/product-reviews/review-overview.tsx" /* webpackChunkName: "component---src-pages-blocks-product-reviews-review-overview-tsx" */),
  "component---src-pages-blocks-product-reviews-review-quick-overview-tsx": () => import("./../../../src/pages/blocks/product-reviews/review-quick-overview.tsx" /* webpackChunkName: "component---src-pages-blocks-product-reviews-review-quick-overview-tsx" */),
  "component---src-pages-blocks-progress-steps-index-tsx": () => import("./../../../src/pages/blocks/progress-steps/index.tsx" /* webpackChunkName: "component---src-pages-blocks-progress-steps-index-tsx" */),
  "component---src-pages-blocks-progress-steps-linear-steps-tsx": () => import("./../../../src/pages/blocks/progress-steps/linear-steps.tsx" /* webpackChunkName: "component---src-pages-blocks-progress-steps-linear-steps-tsx" */),
  "component---src-pages-blocks-progress-steps-vertical-stepper-tsx": () => import("./../../../src/pages/blocks/progress-steps/vertical-stepper.tsx" /* webpackChunkName: "component---src-pages-blocks-progress-steps-vertical-stepper-tsx" */),
  "component---src-pages-blocks-progress-steps-with-numbers-only-tsx": () => import("./../../../src/pages/blocks/progress-steps/with-numbers-only.tsx" /* webpackChunkName: "component---src-pages-blocks-progress-steps-with-numbers-only-tsx" */),
  "component---src-pages-blocks-shopping-carts-cart-with-order-summery-tsx": () => import("./../../../src/pages/blocks/shopping-carts/cart-with-order-summery.tsx" /* webpackChunkName: "component---src-pages-blocks-shopping-carts-cart-with-order-summery-tsx" */),
  "component---src-pages-blocks-shopping-carts-empty-cart-tsx": () => import("./../../../src/pages/blocks/shopping-carts/empty-cart.tsx" /* webpackChunkName: "component---src-pages-blocks-shopping-carts-empty-cart-tsx" */),
  "component---src-pages-blocks-shopping-carts-index-tsx": () => import("./../../../src/pages/blocks/shopping-carts/index.tsx" /* webpackChunkName: "component---src-pages-blocks-shopping-carts-index-tsx" */),
  "component---src-pages-blocks-sidebars-index-tsx": () => import("./../../../src/pages/blocks/sidebars/index.tsx" /* webpackChunkName: "component---src-pages-blocks-sidebars-index-tsx" */),
  "component---src-pages-blocks-sidebars-simple-tsx": () => import("./../../../src/pages/blocks/sidebars/simple.tsx" /* webpackChunkName: "component---src-pages-blocks-sidebars-simple-tsx" */),
  "component---src-pages-blocks-sidebars-with-collapsible-menu-items-tsx": () => import("./../../../src/pages/blocks/sidebars/with-collapsible-menu-items.tsx" /* webpackChunkName: "component---src-pages-blocks-sidebars-with-collapsible-menu-items-tsx" */),
  "component---src-pages-blocks-sidebars-with-dark-bg-tsx": () => import("./../../../src/pages/blocks/sidebars/with-dark-bg.tsx" /* webpackChunkName: "component---src-pages-blocks-sidebars-with-dark-bg-tsx" */),
  "component---src-pages-blocks-stats-client-satisfaction-tsx": () => import("./../../../src/pages/blocks/stats/client-satisfaction.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-client-satisfaction-tsx" */),
  "component---src-pages-blocks-stats-index-tsx": () => import("./../../../src/pages/blocks/stats/index.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-index-tsx" */),
  "component---src-pages-blocks-stats-stats-with-card-tsx": () => import("./../../../src/pages/blocks/stats/stats-with-card.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-stats-with-card-tsx" */),
  "component---src-pages-blocks-stats-with-abstract-visual-representation-tsx": () => import("./../../../src/pages/blocks/stats/with-abstract-visual-representation.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-with-abstract-visual-representation-tsx" */),
  "component---src-pages-blocks-stats-with-bordered-cards-and-brand-color-tsx": () => import("./../../../src/pages/blocks/stats/with-bordered-cards-and-brand-color.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-with-bordered-cards-and-brand-color-tsx" */),
  "component---src-pages-blocks-stats-with-count-up-numbers-and-cover-image-tsx": () => import("./../../../src/pages/blocks/stats/with-count-up-numbers-and-cover-image.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-with-count-up-numbers-and-cover-image-tsx" */),
  "component---src-pages-blocks-stats-with-count-up-numbers-and-map-tsx": () => import("./../../../src/pages/blocks/stats/with-count-up-numbers-and-map.tsx" /* webpackChunkName: "component---src-pages-blocks-stats-with-count-up-numbers-and-map-tsx" */),
  "component---src-pages-blocks-store-navigation-index-tsx": () => import("./../../../src/pages/blocks/store-navigation/index.tsx" /* webpackChunkName: "component---src-pages-blocks-store-navigation-index-tsx" */),
  "component---src-pages-blocks-store-navigation-nav-with-centered-search-tsx": () => import("./../../../src/pages/blocks/store-navigation/nav-with-centered-search.tsx" /* webpackChunkName: "component---src-pages-blocks-store-navigation-nav-with-centered-search-tsx" */),
  "component---src-pages-blocks-store-popups-index-tsx": () => import("./../../../src/pages/blocks/store-popups/index.tsx" /* webpackChunkName: "component---src-pages-blocks-store-popups-index-tsx" */),
  "component---src-pages-blocks-store-popups-popup-with-discount-tsx": () => import("./../../../src/pages/blocks/store-popups/popup-with-discount.tsx" /* webpackChunkName: "component---src-pages-blocks-store-popups-popup-with-discount-tsx" */),
  "component---src-pages-blocks-store-popups-popup-with-image-tsx": () => import("./../../../src/pages/blocks/store-popups/popup-with-image.tsx" /* webpackChunkName: "component---src-pages-blocks-store-popups-popup-with-image-tsx" */),
  "component---src-pages-blocks-tables-index-tsx": () => import("./../../../src/pages/blocks/tables/index.tsx" /* webpackChunkName: "component---src-pages-blocks-tables-index-tsx" */),
  "component---src-pages-blocks-tables-simple-striped-tsx": () => import("./../../../src/pages/blocks/tables/simple-striped.tsx" /* webpackChunkName: "component---src-pages-blocks-tables-simple-striped-tsx" */),
  "component---src-pages-blocks-tables-simple-tsx": () => import("./../../../src/pages/blocks/tables/simple.tsx" /* webpackChunkName: "component---src-pages-blocks-tables-simple-tsx" */),
  "component---src-pages-blocks-tables-with-avatars-and-multiline-content-tsx": () => import("./../../../src/pages/blocks/tables/with-avatars-and-multiline-content.tsx" /* webpackChunkName: "component---src-pages-blocks-tables-with-avatars-and-multiline-content-tsx" */),
  "component---src-pages-blocks-team-index-tsx": () => import("./../../../src/pages/blocks/team/index.tsx" /* webpackChunkName: "component---src-pages-blocks-team-index-tsx" */),
  "component---src-pages-blocks-team-team-with-circled-photos-tsx": () => import("./../../../src/pages/blocks/team/team-with-circled-photos.tsx" /* webpackChunkName: "component---src-pages-blocks-team-team-with-circled-photos-tsx" */),
  "component---src-pages-blocks-team-team-with-small-square-photos-tsx": () => import("./../../../src/pages/blocks/team/team-with-small-square-photos.tsx" /* webpackChunkName: "component---src-pages-blocks-team-team-with-small-square-photos-tsx" */),
  "component---src-pages-blocks-team-with-alternate-card-tsx": () => import("./../../../src/pages/blocks/team/with-alternate-card.tsx" /* webpackChunkName: "component---src-pages-blocks-team-with-alternate-card-tsx" */),
  "component---src-pages-blocks-team-with-large-images-and-social-icons-tsx": () => import("./../../../src/pages/blocks/team/with-large-images-and-social-icons.tsx" /* webpackChunkName: "component---src-pages-blocks-team-with-large-images-and-social-icons-tsx" */),
  "component---src-pages-blocks-team-with-overlapped-cards-tsx": () => import("./../../../src/pages/blocks/team/with-overlapped-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-team-with-overlapped-cards-tsx" */),
  "component---src-pages-blocks-team-with-simple-card-tsx": () => import("./../../../src/pages/blocks/team/with-simple-card.tsx" /* webpackChunkName: "component---src-pages-blocks-team-with-simple-card-tsx" */),
  "component---src-pages-blocks-testimonials-index-tsx": () => import("./../../../src/pages/blocks/testimonials/index.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-index-tsx" */),
  "component---src-pages-blocks-testimonials-reviews-with-bordered-grid-layout-tsx": () => import("./../../../src/pages/blocks/testimonials/reviews-with-bordered-grid-layout.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-reviews-with-bordered-grid-layout-tsx" */),
  "component---src-pages-blocks-testimonials-reviews-with-simple-boxes-tsx": () => import("./../../../src/pages/blocks/testimonials/reviews-with-simple-boxes.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-reviews-with-simple-boxes-tsx" */),
  "component---src-pages-blocks-testimonials-with-brand-bg-color-tsx": () => import("./../../../src/pages/blocks/testimonials/with-brand-bg-color.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-with-brand-bg-color-tsx" */),
  "component---src-pages-blocks-testimonials-with-company-logo-tsx": () => import("./../../../src/pages/blocks/testimonials/with-company-logo.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-with-company-logo-tsx" */),
  "component---src-pages-blocks-testimonials-with-highlighted-card-tsx": () => import("./../../../src/pages/blocks/testimonials/with-highlighted-card.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-with-highlighted-card-tsx" */),
  "component---src-pages-blocks-testimonials-with-large-image-tsx": () => import("./../../../src/pages/blocks/testimonials/with-large-image.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-with-large-image-tsx" */),
  "component---src-pages-blocks-testimonials-with-outlined-cards-tsx": () => import("./../../../src/pages/blocks/testimonials/with-outlined-cards.tsx" /* webpackChunkName: "component---src-pages-blocks-testimonials-with-outlined-cards-tsx" */),
  "component---src-pages-blocks-user-cards-index-tsx": () => import("./../../../src/pages/blocks/user-cards/index.tsx" /* webpackChunkName: "component---src-pages-blocks-user-cards-index-tsx" */),
  "component---src-pages-blocks-user-cards-user-card-grid-tsx": () => import("./../../../src/pages/blocks/user-cards/user-card-grid.tsx" /* webpackChunkName: "component---src-pages-blocks-user-cards-user-card-grid-tsx" */),
  "component---src-pages-blocks-user-cards-user-card-with-background-tsx": () => import("./../../../src/pages/blocks/user-cards/user-card-with-background.tsx" /* webpackChunkName: "component---src-pages-blocks-user-cards-user-card-with-background-tsx" */),
  "component---src-pages-blocks-user-cards-user-card-with-rating-tsx": () => import("./../../../src/pages/blocks/user-cards/user-card-with-rating.tsx" /* webpackChunkName: "component---src-pages-blocks-user-cards-user-card-with-rating-tsx" */),
  "component---src-pages-blog-newsroom-tsx": () => import("./../../../src/pages/blog-newsroom.tsx" /* webpackChunkName: "component---src-pages-blog-newsroom-tsx" */),
  "component---src-pages-blog-reach-view-tsx": () => import("./../../../src/pages/blog-reach-view.tsx" /* webpackChunkName: "component---src-pages-blog-reach-view-tsx" */),
  "component---src-pages-campaign-management-tsx": () => import("./../../../src/pages/campaign-management.tsx" /* webpackChunkName: "component---src-pages-campaign-management-tsx" */),
  "component---src-pages-career-listing-minimal-tsx": () => import("./../../../src/pages/career-listing-minimal.tsx" /* webpackChunkName: "component---src-pages-career-listing-minimal-tsx" */),
  "component---src-pages-career-listing-tsx": () => import("./../../../src/pages/career-listing.tsx" /* webpackChunkName: "component---src-pages-career-listing-tsx" */),
  "component---src-pages-career-opening-tsx": () => import("./../../../src/pages/career-opening.tsx" /* webpackChunkName: "component---src-pages-career-opening-tsx" */),
  "component---src-pages-cloud-hosting-tsx": () => import("./../../../src/pages/cloud-hosting.tsx" /* webpackChunkName: "component---src-pages-cloud-hosting-tsx" */),
  "component---src-pages-company-terms-tsx": () => import("./../../../src/pages/company-terms.tsx" /* webpackChunkName: "component---src-pages-company-terms-tsx" */),
  "component---src-pages-contact-page-cover-tsx": () => import("./../../../src/pages/contact-page-cover.tsx" /* webpackChunkName: "component---src-pages-contact-page-cover-tsx" */),
  "component---src-pages-contact-page-tsx": () => import("./../../../src/pages/contact-page.tsx" /* webpackChunkName: "component---src-pages-contact-page-tsx" */),
  "component---src-pages-contact-sidebar-map-tsx": () => import("./../../../src/pages/contact-sidebar-map.tsx" /* webpackChunkName: "component---src-pages-contact-sidebar-map-tsx" */),
  "component---src-pages-coworking-tsx": () => import("./../../../src/pages/coworking.tsx" /* webpackChunkName: "component---src-pages-coworking-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-demo-2-tsx": () => import("./../../../src/pages/demo2.tsx" /* webpackChunkName: "component---src-pages-demo-2-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-demos-ecommerce-cart-tsx": () => import("./../../../src/pages/demos/ecommerce/cart.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-cart-tsx" */),
  "component---src-pages-demos-ecommerce-checkout-tsx": () => import("./../../../src/pages/demos/ecommerce/checkout.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-checkout-tsx" */),
  "component---src-pages-demos-ecommerce-empty-cart-tsx": () => import("./../../../src/pages/demos/ecommerce/empty-cart.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-empty-cart-tsx" */),
  "component---src-pages-demos-ecommerce-index-tsx": () => import("./../../../src/pages/demos/ecommerce/index.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-index-tsx" */),
  "component---src-pages-demos-ecommerce-listing-tsx": () => import("./../../../src/pages/demos/ecommerce/listing.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-listing-tsx" */),
  "component---src-pages-demos-ecommerce-order-complete-tsx": () => import("./../../../src/pages/demos/ecommerce/order-complete.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-order-complete-tsx" */),
  "component---src-pages-demos-ecommerce-product-overview-tsx": () => import("./../../../src/pages/demos/ecommerce/product-overview.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-product-overview-tsx" */),
  "component---src-pages-demos-ecommerce-promotions-tsx": () => import("./../../../src/pages/demos/ecommerce/promotions.tsx" /* webpackChunkName: "component---src-pages-demos-ecommerce-promotions-tsx" */),
  "component---src-pages-demos-index-tsx": () => import("./../../../src/pages/demos/index.tsx" /* webpackChunkName: "component---src-pages-demos-index-tsx" */),
  "component---src-pages-design-company-tsx": () => import("./../../../src/pages/design-company.tsx" /* webpackChunkName: "component---src-pages-design-company-tsx" */),
  "component---src-pages-desktop-app-tsx": () => import("./../../../src/pages/desktop-app.tsx" /* webpackChunkName: "component---src-pages-desktop-app-tsx" */),
  "component---src-pages-docs-change-log-tsx": () => import("./../../../src/pages/docs/change-log.tsx" /* webpackChunkName: "component---src-pages-docs-change-log-tsx" */),
  "component---src-pages-docs-colors-tsx": () => import("./../../../src/pages/docs/colors.tsx" /* webpackChunkName: "component---src-pages-docs-colors-tsx" */),
  "component---src-pages-docs-container-tsx": () => import("./../../../src/pages/docs/container.tsx" /* webpackChunkName: "component---src-pages-docs-container-tsx" */),
  "component---src-pages-docs-icons-tsx": () => import("./../../../src/pages/docs/icons.tsx" /* webpackChunkName: "component---src-pages-docs-icons-tsx" */),
  "component---src-pages-docs-illustrations-tsx": () => import("./../../../src/pages/docs/illustrations.tsx" /* webpackChunkName: "component---src-pages-docs-illustrations-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-introduction-tsx": () => import("./../../../src/pages/docs/introduction.tsx" /* webpackChunkName: "component---src-pages-docs-introduction-tsx" */),
  "component---src-pages-docs-layouts-tsx": () => import("./../../../src/pages/docs/layouts.tsx" /* webpackChunkName: "component---src-pages-docs-layouts-tsx" */),
  "component---src-pages-docs-page-tsx": () => import("./../../../src/pages/docs/page.tsx" /* webpackChunkName: "component---src-pages-docs-page-tsx" */),
  "component---src-pages-docs-quick-start-gatsbyjs-tsx": () => import("./../../../src/pages/docs/quick-start-gatsbyjs.tsx" /* webpackChunkName: "component---src-pages-docs-quick-start-gatsbyjs-tsx" */),
  "component---src-pages-docs-quick-start-nextjs-tsx": () => import("./../../../src/pages/docs/quick-start-nextjs.tsx" /* webpackChunkName: "component---src-pages-docs-quick-start-nextjs-tsx" */),
  "component---src-pages-docs-quick-start-react-scripts-tsx": () => import("./../../../src/pages/docs/quick-start-react-scripts.tsx" /* webpackChunkName: "component---src-pages-docs-quick-start-react-scripts-tsx" */),
  "component---src-pages-docs-setup-tsx": () => import("./../../../src/pages/docs/setup.tsx" /* webpackChunkName: "component---src-pages-docs-setup-tsx" */),
  "component---src-pages-docs-shadows-tsx": () => import("./../../../src/pages/docs/shadows.tsx" /* webpackChunkName: "component---src-pages-docs-shadows-tsx" */),
  "component---src-pages-docs-support-tsx": () => import("./../../../src/pages/docs/support.tsx" /* webpackChunkName: "component---src-pages-docs-support-tsx" */),
  "component---src-pages-docs-typography-tsx": () => import("./../../../src/pages/docs/typography.tsx" /* webpackChunkName: "component---src-pages-docs-typography-tsx" */),
  "component---src-pages-e-commerce-tsx": () => import("./../../../src/pages/e-commerce.tsx" /* webpackChunkName: "component---src-pages-e-commerce-tsx" */),
  "component---src-pages-e-learning-tsx": () => import("./../../../src/pages/e-learning.tsx" /* webpackChunkName: "component---src-pages-e-learning-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-expo-tsx": () => import("./../../../src/pages/expo.tsx" /* webpackChunkName: "component---src-pages-expo-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-help-center-article-tsx": () => import("./../../../src/pages/help-center-article.tsx" /* webpackChunkName: "component---src-pages-help-center-article-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-hire-us-tsx": () => import("./../../../src/pages/hire-us.tsx" /* webpackChunkName: "component---src-pages-hire-us-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-job-listing-tsx": () => import("./../../../src/pages/job-listing.tsx" /* webpackChunkName: "component---src-pages-job-listing-tsx" */),
  "component---src-pages-logistics-tsx": () => import("./../../../src/pages/logistics.tsx" /* webpackChunkName: "component---src-pages-logistics-tsx" */),
  "component---src-pages-mobile-app-tsx": () => import("./../../../src/pages/mobile-app.tsx" /* webpackChunkName: "component---src-pages-mobile-app-tsx" */),
  "component---src-pages-not-found-cover-tsx": () => import("./../../../src/pages/not-found-cover.tsx" /* webpackChunkName: "component---src-pages-not-found-cover-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/not-found.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-password-reset-cover-tsx": () => import("./../../../src/pages/password-reset-cover.tsx" /* webpackChunkName: "component---src-pages-password-reset-cover-tsx" */),
  "component---src-pages-password-reset-simple-tsx": () => import("./../../../src/pages/password-reset-simple.tsx" /* webpackChunkName: "component---src-pages-password-reset-simple-tsx" */),
  "component---src-pages-portfolio-grid-tsx": () => import("./../../../src/pages/portfolio-grid.tsx" /* webpackChunkName: "component---src-pages-portfolio-grid-tsx" */),
  "component---src-pages-portfolio-masonry-tsx": () => import("./../../../src/pages/portfolio-masonry.tsx" /* webpackChunkName: "component---src-pages-portfolio-masonry-tsx" */),
  "component---src-pages-portfolio-page-tsx": () => import("./../../../src/pages/portfolio-page.tsx" /* webpackChunkName: "component---src-pages-portfolio-page-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-rental-tsx": () => import("./../../../src/pages/rental.tsx" /* webpackChunkName: "component---src-pages-rental-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-signin-cover-tsx": () => import("./../../../src/pages/signin-cover.tsx" /* webpackChunkName: "component---src-pages-signin-cover-tsx" */),
  "component---src-pages-signin-simple-tsx": () => import("./../../../src/pages/signin-simple.tsx" /* webpackChunkName: "component---src-pages-signin-simple-tsx" */),
  "component---src-pages-signup-cover-tsx": () => import("./../../../src/pages/signup-cover.tsx" /* webpackChunkName: "component---src-pages-signup-cover-tsx" */),
  "component---src-pages-signup-simple-tsx": () => import("./../../../src/pages/signup-simple.tsx" /* webpackChunkName: "component---src-pages-signup-simple-tsx" */),
  "component---src-pages-startup-tsx": () => import("./../../../src/pages/startup.tsx" /* webpackChunkName: "component---src-pages-startup-tsx" */),
  "component---src-pages-strategy-tsx": () => import("./../../../src/pages/strategy.tsx" /* webpackChunkName: "component---src-pages-strategy-tsx" */),
  "component---src-pages-verticle-tsx": () => import("./../../../src/pages/verticle.tsx" /* webpackChunkName: "component---src-pages-verticle-tsx" */),
  "component---src-pages-web-basic-tsx": () => import("./../../../src/pages/web-basic.tsx" /* webpackChunkName: "component---src-pages-web-basic-tsx" */)
}

